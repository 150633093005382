export default [
  {
    key: 'no',
    label: 'field.receiptNo',
    type: 'text',
    operator: '%',
  },
  {
    key: 'transferOrderNo',
    label: 'field.orderNo',
    type: 'text',
    operator: '%',
  },
  {
    key: 'transferReceiptDate',
    label: 'field.receiptDate',
    type: 'date-range-picker',
    cast: 'range',
    firstOperator: 'd>=',
    secondOperator: 'd<=',
  },
];
